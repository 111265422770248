export default{
    userInfo: state=>state.User.userInfo,
    token: state=>state.User.token,
    menu: state=>state.Menu.menuList,
    handleMenu: state=>state.Menu.handleMenu,
    menuFirstInfo: state=>state.Menu.menuFirstInfo,
    menuActive: state=>state.Menu.menuActive,
    menuSliderActive: state=>state.Menu.menuSliderActive,
    menuTopList: state=>state.Menu.menuTopList,
    noticeInfo: state=>state.Notice.notice,
    getDragQuestionNo: state=>state.Notice.dragQuestionNo,
    noticeSet: state=>state.Notice.noticeSet,
    dict: state=>state.Dict.dict,
    processNum: state=>state.Notice.processNum,
    answerTest:state=>state.Notice.answerTest,
    sectionIndex:state=>state.Notice.sectionIndex,
    mappingList:state=>state.Notice.mapping,
    programData:state=>state.User.programData,
    projectInfo: state=>state.Notice.projectInfo
}